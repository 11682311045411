import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";

import ContentfulImage from "../components/ContentfulImage";
import { Link } from "gatsby";

interface VisionProps {
  image: string;
  title: string;
  text: string;
  linkLabel: string;
}

const Vision = ({ image, title, text, linkLabel }: VisionProps) => (
  <Root>
    <Stripe />
    <Content container alignItems="center">
      <ImageWrapper item xs={12} sm={5}>
        <ContentfulImage url={image} />
      </ImageWrapper>
      <TextWrapper item xs={12} sm={7}>
        <Headline variant="h2">{title}</Headline>
        <Typography>{text}</Typography>
        <LinkWrapper>
          <StyledLink to="/about-us">{linkLabel}</StyledLink>
        </LinkWrapper>
      </TextWrapper>
    </Content>
  </Root>
);

export default Vision;

const Root = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.dust.main};
    margin-top: ${theme.spacing(5)}px;
    padding: ${theme.spacing(4, 3)};
    width: 100%;
    margin-bottom: ${theme.spacing(3)}px;
    position: relative;
    ${theme.breakpoints.up("md")} {
      background-color: transparent;
      margin-bottom: ${theme.spacing(8)}px;
      padding: ${theme.spacing(0, 7)};
    }
`
);

const Stripe = styled.div(
  ({ theme }) => `
    ${theme.breakpoints.up("sm")} {
        background-color: ${theme.palette.dust.main};
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }


    ${theme.breakpoints.up("md")} {
      height: 84%;
      top: 8%;
    }

    @media (max-width: 1133px) {
        height: 100%;
        top: 0;
    }
`
);

const Content = styled(Grid)(
  ({ theme }) => `
 max-width: 1280px;
 margin:0 auto;
`
);

const ImageWrapper = styled(Grid)(({ theme }) => ``);

const TextWrapper = styled(Grid)(
  ({ theme }) => `
    margin-top: ${theme.spacing(3)}px;
    z-index: 1;
    padding: 0;
    ${theme.breakpoints.up("sm")} {
      margin-top: 0;
      padding: 0 0 0 8.33%;
    }
    ${theme.breakpoints.up("md")} {
        margin-top: 0;
        padding: 0 3.33%;
    }
    ${theme.breakpoints.up("lg")} {
        margin-top: 0;
        padding: 0 8.33%;
    }
`
);

const Headline = styled(Typography)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(2)}px;
`
);

const StyledLink = styled(Link)(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
    font-weight: 700;
    &:hover {
      color: ${theme.palette.black.main} !important; 
    }
`
);

const LinkWrapper = styled.div(
  ({ theme }) => `
margin-top: ${theme.spacing(3)}px;

`
);
