import React from "react";
import styled from "styled-components";
import { Box, Grid, Hidden, Typography } from "@material-ui/core";
import {
  ContentfulAsset,
  ContentfulAssetFile,
  Maybe,
  Scalars,
} from "types/graphql-types";
import ContentfulRichText from "../components/ContentfulRichText";
import ContentfulImage from "../components/ContentfulImage";
import { useIntl } from "gatsby-plugin-intl"

type Image = Maybe<
  Pick<ContentfulAsset, "id" | "description"> & {
    file: Maybe<Pick<ContentfulAssetFile, "url">>;
  }
>;

interface Props {
  title: string | undefined | null;
  text: Scalars["JSON"] | undefined | null;
  mobileImage: Image;
  desktopImage: Image;
  mailAddress: string | undefined | null;
}

const ExpansionTeaser = ({
  title,
  text,
  desktopImage,
  mobileImage,
  mailAddress,
}: Props) => {
  const intl = useIntl()
  return (
    <Grid container justify="center" style={{ marginRight: 40 }}>
      <Hidden xsDown mdUp>
        <Grid item xs={12} sm={5} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            <TitleSm variant="h1">{title}</TitleSm>
          </Box>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <GridItem item xs={12} sm={7} md={6}>
          {mobileImage && (
            <ContentfulImage
              url={mobileImage.file!.url!}
              alt={mobileImage.description!}
            />
          )}
        </GridItem>
      </Hidden>
      <Grid item xs={12} md={6}>
        <TitleXsDownMdUp variant="h1">{title}</TitleXsDownMdUp>
        <Box maxWidth={600}>
          <ContentfulRichText json={text} />
        </Box>
        <LinkWrapper href={`mailto:${mailAddress}`} target="_blank">
          {intl.formatMessage({ id: 'contactButton' })}
        </LinkWrapper>
      </Grid>
      <Hidden smDown>
        <GridItem item xs={12} md={6}>
          <ImageWrapper>
            {desktopImage && (
              <ContentfulImage
                url={desktopImage.file!.url!}
                alt={desktopImage.description!}
              />
            )}
          </ImageWrapper>
        </GridItem>
      </Hidden>
    </Grid>
  );
};

export default ExpansionTeaser;

const TitleSm = styled(Typography)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(4)}px;
  width:206px;
  ${theme.breakpoints.up("sm")}{
    width: 100%;
    width:260px;
  }
`
);

const TitleXsDownMdUp = styled(Typography)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(4)}px;
  width:206px;
  ${theme.breakpoints.up("sm")}{
    display: none;
    width: 100%;
  }
  ${theme.breakpoints.up("md")}{
    display: block;
  }
`
);

const LinkWrapper = styled.a(
  ({ theme }) => `
  background: ${theme.palette.primary.main};
  color: ${theme.palette.primary.contrastText};
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  margin-top: ${theme.spacing(3)}px;
  padding: ${theme.spacing(2, 10)};
  display: inline-block;
  cursor: pointer;
  ${theme.breakpoints.up("md")}{
    margin-top: ${theme.spacing(7)}px;
  }
  &:hover {
    color: ${theme.palette.primary.contrastText} !important; 
  }
`
);

const ImageWrapper = styled.div(
  ({ theme }) => `
width: 100%;
padding-left: 10%;
`
);

const GridItem = styled(Grid)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(3)}px;
  ${theme.breakpoints.up("sm")}{
    padding-left: 40px;
  }
  ${theme.breakpoints.up("md")}{
    margin-bottom: 0;

  }

`
);
