import React from "react";
import styled from "styled-components";
import { Typography, Grid, Hidden } from "@material-ui/core";
import BlogPost from "src/common/components/BlogPost";
import DownloadLink from "../components/DownloadLink";
import { Link } from "gatsby";
import ContentWidthWrapper from "../components/ContentWidthWrapper";

const PressTeaser = ({ pressTitle, pressSubtitle, pressMoreLabel, nodes, downloads }) => {
  return (
    <Wrapper>
      <ContentWidthWrapper>
        <Grid container justify="space-between">
          <DonwloadsWrapper item xs={12} md={4}>
            <TextWrapper>
              <Hidden smDown>
                <Typography variant="h1" component="h2">
                  {pressTitle}
                </Typography>
              </Hidden>
              <Hidden mdUp>
                <Typography variant="h2">{pressTitle}</Typography>
              </Hidden>

              <Typography variant="subtitle1">{pressSubtitle}</Typography>
            </TextWrapper>
            {downloads?.map((download: any) => (
              <DownloadLink
                href={download?.localFile?.publicURL!}
                fileName={download?.title!}
                key={download.title}
              />
            ))}
          </DonwloadsWrapper>

          <Grid item xs={12} md={7}>
            {nodes.map((news: any) => (
              <BlogPost
                date={news.creationDate}
                title={news.title}
                description={news.description}
                link={news.slug}
                key={news.id}
              />
            ))}
            <LinkWrapper>
              <LinkButton to="/news">{pressMoreLabel}</LinkButton>
            </LinkWrapper>
          </Grid>
        </Grid>
      </ContentWidthWrapper>
    </Wrapper>
  );
};

export default PressTeaser;

const Wrapper = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.dust.main};
    margin-bottom: ${theme.spacing(5)}px;
    padding: ${theme.spacing(8, 3, 3)};
    ${theme.breakpoints.up("md")} {
      margin-bottom: ${theme.spacing(11)}px;
      padding: ${theme.spacing(8, 4, 4)};
    }

`
);

const DonwloadsWrapper = styled(Grid)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(4)}px;
  ${theme.breakpoints.up("md")} {
    margin-bottom: 0;
  }
`
);

const TextWrapper = styled("div")(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(7)}px;
`
);

const LinkButton = styled(Link)(
  ({ theme }) => `
    color: ${theme.palette.primary.contrastText};
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    &:hover {
      color: ${theme.palette.primary.contrastText} !important; 
    }
`
);

const LinkWrapper = styled("div")(
  ({ theme }) => `
  background-color: ${theme.palette.primary.main};
  padding: ${theme.spacing(2, 4)};
  width: fit-content;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-width: 155px;
  }
`
);
