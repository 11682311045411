import React from "react";
import styled from "styled-components";
import { Typography, Grid, Hidden } from "@material-ui/core";
import ContentfulImage from "../components/ContentfulImage";
import AnchorLink from "../components/AnchorLink";
import { Maybe, ContentfulAssetFile } from "types/graphql-types";

interface BrandsProps {
  title: string;
  linkLabel: string;
  brandsList: Array<
    Maybe<{
      teaserImage: Maybe<{ file: Maybe<Pick<ContentfulAssetFile, "url">> }>;
    }>
  >;
}

const Brands = ({ title, brandsList, linkLabel }: BrandsProps) => (
  <Wrapper>
    <Headline container justify="space-between" alignItems="flex-end">
      <LeftSide item xs={12} sm={8}>
        <Hidden smDown>
          <Typography variant="h1" component="h2">
            {title}
          </Typography>
        </Hidden>
        <Hidden mdUp>
          <Title variant="h2">{title}</Title>
        </Hidden>
      </LeftSide>
      <Grid item xs={12} sm={4} container justify="flex-end">
        <AnchorLink to="/brands">{linkLabel}</AnchorLink>
      </Grid>
    </Headline>
    <ImageWrapper>
      {brandsList.map((item) => (
        <WrapperImg key={item?.teaserImage?.file?.url!}>
          <ContentfulImage
            url={item?.teaserImage?.file?.url!}
            width={"100%"}
            height={"auto"}
          />
        </WrapperImg>
      ))}
    </ImageWrapper>
  </Wrapper>
);

export default Brands;

const Wrapper = styled.div(({ theme }) => ``);

const Headline = styled(Grid)(
  ({ theme }) => `
margin-bottom: ${theme.spacing(2)}px;
${theme.breakpoints.up("md")}{
  margin-bottom: ${theme.spacing(5)}px;
}
`
);
const LeftSide = styled(Grid)(
  ({ theme }) => `
  ${theme.breakpoints.up("sm")} {
margin-bottom: ${theme.spacing(4)}px;
  }
`
);
const Title = styled(Typography)(
  ({ theme }) => `
margin-bottom: ${theme.spacing(4)}px;

`
);

const ImageWrapper = styled.div(
  ({ theme }) => `
display: flex;
flex-direction: column;
align-items: center;
${theme.breakpoints.up("sm")}{
flex-direction: row;
justify-content: space-between;
}
`
);

const WrapperImg = styled.div(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(3)}px;
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${theme.breakpoints.up("sm")}{
  margin-right: ${theme.spacing(3)}px;
  margin-bottom: 0;
  flex-basis: 33%;
  &:last-of-type {
    margin-right: 0;
  }
}
`
);
