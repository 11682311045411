import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { Color } from "src/pages/";

interface FactProps {
  number: string;
  subtitle: string;
  text: string;
  color: Color;
}

const Fact = ({ number, subtitle, text, color }: FactProps) => (
  <Root color={color}>
    <Number>{number}</Number>
    <Subtitle variant="h5">{subtitle}</Subtitle>
    <Typography variant="subtitle2" style={{ fontWeight: 400 }}>
      {text}
    </Typography>
  </Root>
);

export default Fact;

const Root = styled.div<{ color: Color }>(
  ({ theme, color }) => `
    background-color: ${theme.palette[color].main};
    padding: ${theme.spacing(4, 3)};
    text-align: center;
    height: 100%;
    ${theme.breakpoints.up("sm")} {
      padding: ${theme.spacing(4, 5)};
    }
    ${theme.breakpoints.up("md")} {
      padding: ${theme.spacing(6, 6.75)};
    }
    ${theme.breakpoints.up("lg")} {
      padding: ${theme.spacing(8, 9)};
    }
`
);

const Number = styled.p(
  ({ theme }) => `
    font-size: 56px;
    line-height: 70px;
    color: ${theme.palette.primary.main};
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: 700;
    margin-bottom: ${theme.spacing(1)}px;
    ${theme.breakpoints.up("md")}{
      font-size: 64px;
    line-height: 76px;
    }
    ${theme.breakpoints.up("lg")}{
      font-size: 80px;
    line-height: 88px;
    }
`
);

const Subtitle = styled(Typography)(
  ({ theme }) => `
    text-transform: none;
    margin-bottom: ${theme.spacing(2)}px;
`
);
