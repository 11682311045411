import React from "react";
import styled from "styled-components";
import { Typography, Grid } from "@material-ui/core";
import ContentfulImage from "../ContentfulImage";
import ContentWidthWrapper from "src/common/components/ContentWidthWrapper";
import AnchorLink from "../AnchorLink";

const CareerStage = ({
  title,
  subtitle,
  image,
  anchorLink,
  linkLabel,
  linkAsButton,
}: {
  title: string | undefined | null;
  subtitle: string | undefined | null;
  image: string | undefined | null;
  anchorLink?: string | undefined | null;
  linkLabel?: string | undefined | null;
  linkAsButton?: boolean;
}) => {
  return (
    <Wrapper>
      <ContentWidthWrapper padding>
        <Grid container alignItems="center">
          <Image item xs={12} sm={5}>
            {image && <ContentfulImage url={image} />}
          </Image>
          <TextWrapper item xs={12} sm={6} md={5}>
            <Headline variant="h1">{title}</Headline>
            <Text variant="subtitle1">{subtitle}</Text>
            {anchorLink && (
              <AnchorLink href={anchorLink} buttonStyle={linkAsButton}>
                {linkLabel}
              </AnchorLink>
            )}
          </TextWrapper>
        </Grid>
      </ContentWidthWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${theme.spacing(3)}px;

`
);

const Image = styled(Grid)(
  ({ theme }) => `
  ${theme.breakpoints.up("sm")} {
    margin-right: ${theme.spacing(5)}px;

}
    ${theme.breakpoints.up("md")} {
        margin-right: ${theme.spacing(15)}px;

    }
`
);
const Text = styled(Typography)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(3)}px;

`
);

const TextWrapper = styled(Grid)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing(3)}px;

    ${theme.breakpoints.up("md")} {
        margin-top: ${theme.spacing(0)}px;
    }
`
);

const Headline = styled(Typography)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(2)}px;
  ${theme.breakpoints.up("sm")} {
    margin-bottom: ${theme.spacing(3)}px;
  }
`
);

export default CareerStage;
