import React from "react";
import Layout from "src/common/Layout";
import { graphql } from "gatsby";
import { LandingPageQuery } from "types/graphql-types";
import "react-json-pretty/themes/1337.css";
import LandingPageStage from "src/common/components/Stages/LandingpageStage";
import ContentWidthWrapper from "src/common/components/ContentWidthWrapper";
import Vision from "src/common/landingpage/Vision";
import Warning from "src/common/landingpage/Warning";
import PressTeaser from "src/common/aboutus/PressTeaser";
import CareerStage from "src/common/components/Stages/CareerStage";
import Fact from "src/common/landingpage/Fact";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Brands from "src/common/landingpage/Brands";
import SEO from "src/common/components/seo";
import { LinkEntry } from "src/common/Footer";
import ExpansionTeaser from "src/common/landingpage/ExpansionTeaser";

export type Color = "moss" | "sage" | "mud";

function generateColorArray(length: number) {
  const factColors: Color[] = ["moss", "sage", "mud"];
  let colorArray = factColors;
  for (var i = 1; i < length / 3; i++) {
    colorArray = colorArray.concat(factColors);
  }
  return colorArray;
}

const landingPage = ({
  data: { contentfulLandingPage, allContentfulNews, contentfulPressNewsPage, contentfulFooter, contentfulHeader },
}: {
  data: LandingPageQuery;
}) => {
  if (!contentfulLandingPage || !allContentfulNews || !contentfulPressNewsPage || !contentfulFooter || !contentfulHeader) {
    throw new Error(
      "Not all Contentful requirements met: contentfulLandingPage, allContentfulNews, contentfulPressNewsPage, contentfulFooter, contentfulHeader"
    );
  }
  const {
    pageConfig,
    visionTitle,
    visionSubtitle,
    visionLinkLabel,
    visionImage,
    careerImage,
    careerLinkLabel,
    careerSubtitle,
    careerTitle,
    factsList,
    brandsTitle,
    brandsLinkLabel,
    warningBanner,
    depotExpansionTitle,
    depotExpansionText,
    depotExpansionImageMobile,
    depotExpansionImageDesktop,
    depotExpansionContactMailAddress,
  } = contentfulLandingPage;

  const factColors = generateColorArray(factsList ? factsList.length : 0);
  const brandsList = contentfulLandingPage?.brandsList!;

  return (
    <Layout
      contentfulFooter={contentfulFooter}
      contentfulHeader={contentfulHeader}
      stageContent={
        <LandingPageStage
          title={pageConfig!.title!.title!}
          text={pageConfig!.subtitle!.subtitle!}
          image={pageConfig!.headerImage!.file!.url!}
        />
      }
    >
      <SEO
        title={pageConfig!.seoConfig?.title!}
        description={pageConfig!.seoConfig?.description}
        image={pageConfig!.seoConfig?.image?.localFile?.publicURL!}
      />

      {warningBanner?.enabled && (
        <Warning
          title={warningBanner.title!}
          text={warningBanner.desctiption!}
          image={warningBanner.image!.file!.url!}
          link={warningBanner.link as LinkEntry}
        />
      )}

      <Vision
        title={visionTitle!}
        text={visionSubtitle?.visionSubtitle!}
        linkLabel={visionLinkLabel!}
        image={visionImage!.file!.url!}
      />

      <ContentWidthWrapper padding>
        <Brands
          title={brandsTitle!}
          linkLabel={brandsLinkLabel!}
          brandsList={brandsList}
        />
      </ContentWidthWrapper>
      <CareerSpaceWrapper>
        <CareerStage
          title={careerTitle}
          subtitle={careerSubtitle}
          anchorLink={`https://karriere.depot-online.com/`}
          linkLabel={careerLinkLabel}
          image={careerImage!.file!.url}
          linkAsButton={true}
        />
      </CareerSpaceWrapper>
      <ContentWidthWrapper padding>
        {
          <ExpansionTeaser
            title={depotExpansionTitle}
            text={depotExpansionText?.json}
            mobileImage={depotExpansionImageMobile}
            desktopImage={depotExpansionImageDesktop}
            mailAddress={depotExpansionContactMailAddress}
          />
        }
      </ContentWidthWrapper>
      <PressTeaser
        pressTitle={contentfulLandingPage.pressTitle}
        pressSubtitle={contentfulLandingPage.pressSubtitle}
        pressMoreLabel={contentfulLandingPage.pressMoreLabel}
        nodes={allContentfulNews.nodes}
        downloads={contentfulPressNewsPage.downloads}
      />
      <ContentWidthWrapper padding>
        <Grid container justify="center">
          {factsList?.map((fact, index) => (
            <FactWrapper item xs={12} sm={6} md={4} lg={4} key={fact?.id!}>
              <Fact
                number={fact!.value!}
                color={factColors[index]}
                subtitle={fact!.title!}
                text={fact!.subtitle!}
              />
            </FactWrapper>
          ))}
        </Grid>
      </ContentWidthWrapper>
    </Layout>
  );
};

export const query = graphql`
  query LandingPage  ($locale:String!){
    contentfulLandingPage (node_locale: {eq: $locale}){
      pageConfig {
        title {
          title
        }
        subtitle {
          subtitle
        }
        headerImage {
          file {
            url
          }
        }
        seoConfig {
          id
          image {
            file {
              url
            }
            localFile {
              publicURL
            }
          }
          title
          description
        }
      }

      brandsLinkLabel
      brandsList {
        teaserImage {
          file {
            url
          }
        }
      }
      brandsTitle
      careerImage {
        file {
          url
        }
      }
      careerLinkLabel
      careerSubtitle
      careerTitle
      pressMoreLabel
      pressSubtitle
      pressTitle
      visionLinkLabel
      visionSubtitle {
        visionSubtitle
      }
      warningBanner {
        enabled
        title
        desctiption {
          desctiption
          json
        }
        image {
          file {
            url
          }
        }
        link {
          id
          url
          type
          label
        }
      }
      visionTitle
      visionImage {
        file {
          url
        }
      }
      depotExpansionTitle
      depotExpansionText {
        json
      }
      depotExpansionImageDesktop {
        id
        description
        file {
          url
        }
      }
      depotExpansionImageMobile {
        id
        description
        file {
          url
        }
      }
      depotExpansionContactMailAddress

      factsList {
        title
        subtitle
        value
        valuePrefix
        id
      }
    }
    allContentfulNews(sort: { fields: creationDate, order: DESC }, limit: 2, filter: {node_locale: {eq: $locale}}) {
      nodes {
        id
        slug
        title
        description
        creationDate
        image {
          file {
            url
          }
        }
        creationDate
      }
    }
    contentfulPressNewsPage (node_locale: {eq: $locale}){
      downloads {
        title
        file {
          url
          fileName
          contentType
        }
        localFile {
          publicURL
        }
      }
    }
    contentfulFooter (node_locale: {eq: $locale}){
      title
      text
      copyright
      columnOneTitle
      columnOneLinks {
        id
        label
        url
        type
      }
      columnTwoTitle
      columnTwoLinks {
        id
        label
        url
        type
      }
      columnThreeTitle
      columnThreeLinks {
        id
        label
        url
        type
      }
      instagramLink
      facebookLink
      pinterestLink
      columnFourTitle
      childContentfulFooterLanguagesListJsonNode {
        de
        en
      }
      chosenLang
      deGermanFlag {
        file {
          url
        }
      }
      enEnglishFlag {
        file {
          url
        }
      }
      chosenFlag {
        file {
          url
          fileName
        }
      }
    }
    contentfulHeader (node_locale: {eq: $locale}){
      links {
        id
        label
        url
        type
      }
    }
  }
`;

export default landingPage;

const CareerSpaceWrapper = styled.div(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(3)}px;
${theme.breakpoints.up("md")}{
  margin-bottom: ${theme.spacing(5)}px;
}
`
);

const FactWrapper = styled(Grid)(
  ({ theme }) => `
  padding: ${theme.spacing(0, 0, 3)};
  ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(0, 1.5, 3, 1.5)};
    &:first-of-type {
      padding: ${theme.spacing(0, 1.5, 3, 0)};
    }
    &:last-of-type {
      padding: ${theme.spacing(0, 0, 3, 1.5)};
    }
  }

 `
);
