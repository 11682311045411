import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import { ContentfulNavigationItem } from "types/graphql-types";

import ContentfulImage from "../components/ContentfulImage";
import ContentfulRichText from "src/common/components/ContentfulRichText";
import { Link } from "gatsby";

export type LinkEntry = Pick<
  ContentfulNavigationItem,
  "id" | "label" | "url" | "type"
>;

interface WarningProps {
  image: string;
  title: string;
  text: any;
  link: LinkEntry;
}

const Warning = ({ image, title, text, link }: WarningProps) => {
  return (
    <Root>
      <Stripe />
      <Content container alignItems="center">
        <TextWrapper item xs={12} sm={7}>
          <Headline variant="h2">{title}</Headline>
          <ContentfulRichText json={text.json!} />
          <LinkWrapper>
            {link?.type === "internal" ? (
              <InternLink to={link.url!} key={link.id}>
                {link?.label}
              </InternLink>
            ) : (
              <ExternLink
                key={link.id}
                href={link.url!}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link?.label}
              </ExternLink>
            )}
          </LinkWrapper>
        </TextWrapper>
        <ImageWrapper item xs={12} sm={5}>
          <ContentfulImage url={image} />
        </ImageWrapper>
      </Content>
    </Root>
  );
};

export default Warning;

const LinkWrapper = styled.div`
  margin-top: 24px;
`;

const Root = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.dust.main};
    margin-top: ${theme.spacing(5)}px;
    padding: ${theme.spacing(4, 3)};
    width: 100%;
    margin-bottom: ${theme.spacing(3)}px;
    position: relative;
    ${theme.breakpoints.up("md")} {
      background-color: transparent;
      margin-bottom: ${theme.spacing(8)}px;
      padding: ${theme.spacing(0, 7)};
    }
`
);

const InternLink = styled(Link)`
  font-weight: 700;
  color: black;
  &:visited {
    color: black;
  }
`;
const ExternLink = styled.a`
  font-weight: 700;
  color: black;
  &:visited {
    color: black;
  }
  &:active {
    color: black;
  }
`;

const Stripe = styled.div(
  ({ theme }) => `
    ${theme.breakpoints.up("sm")} {
        background-color: ${theme.palette.dust.main};
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }


    ${theme.breakpoints.up("md")} {
      height: 100%
      top: 8%;
    }

    @media (max-width: 1133px) {
        height: 100%;
        top: 0;
    }
`
);

const Content = styled(Grid)(
  ({ theme }) => `
 max-width: 1280px;
 margin:0 auto;
`
);

const ImageWrapper = styled(Grid)(({ theme }) => ``);

const TextWrapper = styled(Grid)(
  ({ theme }) => `
    margin-top: ${theme.spacing(3)}px;
    z-index: 1;
    padding: 0;
    ${theme.breakpoints.up("sm")} {
      margin-top: 0;
      padding: 0 0 0 8.33%;
    }
    ${theme.breakpoints.up("md")} {
        margin-top: 0;
        padding: 0 3.33%;
    }
    ${theme.breakpoints.up("lg")} {
        margin-top: 0;
        padding: 0 8.33%;
    }

    & a {
      color: ${theme.palette.common.black};
      font-weight: 700;
    }
`
);

const Headline = styled(Typography)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(2)}px;
`
);
